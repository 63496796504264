<template>
    <!-- Address Information -->
    <app-collapse-item title="Address Information" :is-visible="isVisible" @visible="$emit('updatevisible')">
      <b-row>
        <b-col cols="12" md="3">
          <HeroDisplayText
            id="billing-address"
            v-model="address_info.billing_address"
            label="Billing Address"
          />
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col cols="12" md="12">
          <l-map
            :zoom="zoom"
            :center="center"
          >
          <l-tile-layer :url="url" />
          <l-circle
            :lat-lng="circle.center"
            :radius="circle.radius"
            :color="circle.color"
          />
          </l-map>
        </b-col>
      </b-row> -->
    </app-collapse-item>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
} from 'bootstrap-vue'

export default {
  props: {
    address_info: {
      type: Object,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    BTable,
    HeroDisplayText,
  },
}
</script>
