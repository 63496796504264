export default class AccountState {
  constructor() {
    return {
      AccountInformation: false,
      AccountLevelValidations: false,
      CustomLinks: false,
      AddressInformation: false,
      SystemInformation: false,
      SalesOrders: false,
      LedgerEntries: false,
      SalesHistory: false,
      Notes: false,
      AccountHistory: false,
      Invoices: false,
    }
  }
}
