<template>
     <!-- Account Level Validations -->
    <app-collapse-item title="Account Level Validations" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row cols="12">
      <b-col cols="12" md="3">
        <HeroCheckbox
          id="risk-assessment"
          v-model="account_validations.risk_assessment_completed_by_finance"
          label="Risk Assessment Completed by Finance"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroCheckbox
          id="general-term"
          v-model="account_validations.general_term_of_service_signed"
          label="General Term of Service Signed"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroCheckbox
          id="pnd-docs"
          v-model="account_validations.PND_documents_approved_by_finance"
          label="PND Documents Approved by Finance"
          :disabled="true"
        />
      </b-col>
    </b-row>
    <b-row cols="12" class="mt-2">
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="general-term"
          v-model="account_validations.general_term"
          label="General Term Type"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="finance-suggested"
          v-model="account_validations.finance_suggested_payment_terms"
          label="Finance Suggested Payment Terms"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="term-url"
          v-model="account_validations.term_url"
          label="Term URL"
        />
      </b-col>
    </b-row>
    </app-collapse-item>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroDisplayLink from '@/views/components/form/displays/HeroDisplayLink.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
} from 'bootstrap-vue'

export default {
  props: {
    account_validations: {
      type: Object,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    BTable,
    HeroDisplayText,
    HeroDisplayLink,
    HeroCheckbox,
  },
}
</script>
