<template>
  <!-- Notes -->
  <app-collapse-item title="Notes" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-table
      class="mt-2"
      striped
      responsive
    >
      <b-row>
        <b-col cols="12" md="3">
          <HeroDisplayText
            id="name"
            v-model="note"
          />
        </b-col>
      </b-row>
    </b-table>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    AppCollapseItem,
    BTable,
  },
  props: {
    note: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
