<template>
  <!-- Custom Links -->
  <app-collapse-item title="Custom Links" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayLink
          id="general-term-service-eng"
          v-model="custom_link.general_term_service_link_eng"
          :href="custom_link.general_term_service_link_eng"
          label="General Term of Service-English[HLTH](New)"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayLink
          id="general-term-service-thai"
          v-model="custom_link.general_term_service_link_thai"
          :href="custom_link.general_term_service_link_thai"
          label="General Term of Service-Thai[HLTH](New)"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroDisplayLink from '@/views/components/form/displays/HeroDisplayLink.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    BTable,
    HeroDisplayText,
    HeroDisplayLink,
  },
  props: {
    custom_link: {
      type: Object,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
