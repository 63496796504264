<template>
  <!-- System Information -->
  <app-collapse-item title="System Information" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="created-by"
          v-model="system_info.created_date"
          label="Created By"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="last-modified"
          v-model="system_info.last_modified_date"
          label="Last Modified By"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
} from 'bootstrap-vue'

export default {
  props: {
    system_info: {
      type: Object,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    BTable,
    HeroDisplayText,
  },
}
</script>
