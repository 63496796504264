import { render, staticRenderFns } from "./OpportunitiesTab.vue?vue&type=template&id=e0ee712e&"
import script from "./OpportunitiesTab.vue?vue&type=script&lang=js&"
export * from "./OpportunitiesTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports